<template>
  <v-app id="login">
    <v-main>
      <v-container
        fluid
        fill-height
        :class="$store.state.ajustes.class_simple_layout_container"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs10
            sm4
            md3
          >
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(login)">
                <v-flex class="mb-4">
                  <v-img
                    :src="$store.state.ajustes.logo_url ? $store.state.ajustes.logo_url : require('../assets/logo.svg')"
                    max-width="100%"
                  ></v-img>
                </v-flex>

                <v-flex>
                  <ValidationProvider name="E-Mail" rules="required|email" v-slot="{ errors, valid }">
                    <v-text-field
                      type="email"
                      filled
                      v-model="email"
                      append-icon=" "
                      label="E-Mail"
                      required
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex>
                  <ValidationProvider name="Clave de Ingreso" rules="required" v-slot="{ errors, valid }">
                    <v-text-field
                      filled
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      name="input-10-1"
                      label="Clave de Ingreso"
                      counter
                      @click:append="showPassword = !showPassword"
                      :error-messages="errors"
                      :success="valid"
                    ></v-text-field>
                  </ValidationProvider>
                </v-flex>

                <v-flex align-self-center class="mt-4">
                  <v-btn type="submit" block @click.prevent="passes(login)" color="primary"
                    dark :loading="loading">INICIAR SESIÓN</v-btn>
                  <v-btn type="button" block to="forgot" color="secondary" text :disabled="loading">Olvidé Mis Credenciales</v-btn>
                  <v-btn type="button" block to="registro" color="secondary" text :disabled="loading">Crear Cuenta</v-btn>
                </v-flex>
              </v-form>
            </ValidationObserver>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    showPassword: false,
    email: '',
    password: '',
  }),
  methods: {
    login: function () {
      // validar this.valid
      this.loading = true

      sessionStorage.removeItem('token')
      sessionStorage.removeItem('paciente')

      this.$http.post('login', {
        'email': this.email,
        'password': this.password
      })
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem('token', response.data.token)

          this.$store.state.loggedIn = true

          if (response.data.data.roles.includes('ROLE_USER')) {
            sessionStorage.setItem('paciente', response.data.data.paciente_id)
            this.$store.state.paciente = parseInt(response.data.data.paciente_id)
          }

          this.$router.push({name: 'home'}, () => {})
        }
      })
      .catch((error) => {
        let feedbackMsg = ''

        if (error.response) {
          feedbackMsg = error.response.data.message
        } else {
          feedbackMsg = 'Error inesperado. Vuelva a intentarlo por favor.'
        }

        this.$eventHub.$emit('snackbar-message', feedbackMsg)
      })
      .then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
  #login .success--text {
      color: #1B5E20 !important;
      caret-color: #1B5E20 !important;
  }

  #login .error--text {
      color: #D32F2F !important;
      caret-color: #D32F2F !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>
